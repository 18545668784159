.req-backgroundStyle {
  background-color: rgba(22, 21, 21, 0.644);
  position: fixed;
  padding: 100px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
}

.req-modalStyle {
  position: relative;
  padding: 0 0 1rem 0;
  width: 650px;
  min-height: 168px;
  max-height: 100%;
  overflow-y: auto;
  background-color: #fff;
  margin: auto;
  flex-grow: 1;
  z-index: 4;
  border-radius: 4px;
}

.modal-content {
  flex-direction: column;
  display: flex;
}

.modal-header-req {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 51px;
  font-size: 16px;
  background-color: #0d203d;
}

.modal-header-req p {
  color: white;
}

.modal-content {
  flex-direction: column;
  display: flex;
}

.modalButtons {
  padding-top: 2rem;
  padding-right: 3%;
  padding-left: 2%;
  text-align: right;
  float: right;
}

.modalButtons button.cancel-button {
  margin-right: 1.5rem;
  margin-left: 1%;
  border: 2px solid;
}

.cancel-button {
  width: 180px;
  height: 40px;
  border: 2px solid;
}

.confirm-button {
  width: 180px;
  height: 40px;
}
