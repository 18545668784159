@import url("https://fonts.googleapis.com/css2?family=Noto+Serif&family=Varela+Round&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arvo:wght@400;500;700");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700");

$palette-primary-main: #7260cf;
$palette-primary-dark-blue: #0d203d;

.App {
  font-family: "Varela Round", sans-serif;
}

.menu-list-item:before {
  position: absolute;
  left: 0;
  width: 4px;
  height: 100%;
  content: "";
  background-color: $palette-primary-main;
}

.project-modify-buttons {
  text-align: right;
  display: block;
  padding: 15px;
  padding-right: 20px;
}

.ui.icon.button {
  background-color: revert !important;
  color: revert !important;
}

.ui.icon.button.delete-btn {
  color: red !important;
}

.ui.button {
  background-color: #7260cf;
  color: white;
}

.ui.btn.normal:hover {
  background-color: #403675;
  color: white;
}

.empty-list-btn {
  margin: auto;
  height: 40px;
  width: 195px;
  font-size: 16px;
}

#alternativeDisplay .font-secondary {
  font-family: "Noto Serif", serif;
}

#alternativeDisplay {
  text-align: center;
}

#alternativeDisplay h3 {
  font-family: "Varela Round", sans-serif;
  font-size: 22px;
  color: #333333;
  font-weight: 400;
}

#alternativeDisplay p.info {
  font-size: 15px;
  color: #666666;
  padding-top: 20px;
  padding-bottom: 10px;
}

#alternativeDisplay .empty-list-btn button {
  width: 195px;
  height: 40px;
  border-radius: 4px;
}

.font-primary {
  font-family: "Varela Round", sans-serif;
}

.color-primary {
  color: $palette-primary-main;
}

.nav-span {
  font-size: 19px;
  padding-left: 30px;
  padding-right: 10px;
  padding-top: 22px;
  display: flex;
  float: left;
  vertical-align: middle;
  text-align: center;
  height: 70px;
}

.nav-link-auth {
  text-decoration: underline;
  color: #ffffff;
}

.nav-link-auth:hover {
  color: #ffffff;
}

.nav-link {
  text-decoration: underline;
}

.nav-link:hover {
  color: #504392;
}

.nav-current-page {
  font-size: 19px;
  color: #666666;
}

.nav-join {
  color: #666666;
  padding-right: 5px;
}

.appbar-account {
  vertical-align: center;
  display: inline-block;
  font-family: "Noto Serif", serif;
  font-size: 1.1rem;
  color: #ffffff;
}

.section-columns {
  display: flex;
  flex-direction: row;

  .col-1 {
    flex-basis: 25%;
    min-width: 180px;
  }

  .col-2 {
    flex-basis: 75%;
    margin-top: 0px !important;
  }
}

.terms-privacy ol > li {
  display: list-item;
}

.terms-privacy ul > li {
  display: list-item;
}

.terms-header {
  font-size: 20px;
  padding-bottom: 10px;
  padding-top: 20px;
}

.terms-privacy .sub {
  font-size: 14px;
  font-family: "Noto Serif", serif;
  line-height: 1.6;
}

.terms-privacy .sub li {
  padding-bottom: 8px;
}

.terms-privacy {
  padding: 100px 50px 50px;
}

.MuiButton-root {
  min-width: 120px !important;
}

.MuiButton-outlined {
  box-shadow: none !important;
  font-family: "Varela Round", sans-serif !important;
  text-transform: none !important;
  font-size: 15px !important;
  border: 2px solid rgba(114, 96, 207, 0.5) !important;
  background-color: #ffffff !important;
}

.MuiButton-contained {
  box-shadow: none !important;
  font-family: "Varela Round", sans-serif !important;
  text-transform: none !important;
  font-size: 15px !important;
}

.MuiButton-containedPrimary:hover {
  background-color: #483d80 !important;
}

.MuiButton-containedSecondary:hover {
  background-color: #a1486d !important;
}

.empty-list-content {
  margin-top: 80px;
}

.makeStyles-tableHeader-333 {
  background-color: #fafafa !important;
  border-bottom: 1px solid rgb(0, 0, 0, 0.12) !important;
  margin-bottom: 50px !important;
}

.makeStyles-container-19 {
  padding: 0 !important;
}

.MuiTableContainer-root-542 {
  border-top: 1px solid rgb(0, 0, 0, 0.12) !important;
}

.MuiButton-root-32 {
  display: none !important;
  visibility: hidden !important;
}

.save-button {
  padding-bottom: 15px;
}

.edit-header {
  background-color: #fafafa;
  border-bottom: 1px solid rgb(0, 0, 0, 0.12);
}

.section-columns {
  .header {
    display: none !important;
    visibility: hidden !important;
  }
  .col-2 {
    border-left: 1px solid rgb(0, 0, 0, 0.12) !important;
  }
  margin-top: 0 !important;
}

.jobForm {
  margin: 0 !important;
  .segment {
    box-shadow: none !important;
    border: none !important;
    padding: 0 !important;
  }
}

.sectionMenu {
  margin: 0 !important;
  margin-top: 0 !important;
  border-width: 0px !important;
  border-radius: 0 !important;
  border: none !important;
}

.subsection {
  border-radius: 0 !important;
  border-width: 0 !important;
  .divider.ui {
    display: none !important;
  }
}

.subsection-header {
  border: 1px solid rgb(0, 0, 0, 0.12) !important;
  border-right-width: 0px !important;
  border-top-width: 1px !important;
  border-left-width: 0px !important;
  background-color: #fafafa !important;
}

.section {
  border-radius: 0 !important;
  border-width: 0 !important;
}

.sectionMenu .section-selected {
  border-top-width: 0 !important;
  .section-name-caption {
    color: #7260cf !important;
  }
}

.section-name-caption {
  font-family: "Varela Round", sans-serif !important;
}

.MuiContainer-root {
  padding-top: 0 !important;
}

.col-1 {
  .droppable-container {
    margin-top: -1rem !important;
  }
}

.selectEntities {
  padding: 10px;
  font-size: 16px;
}

.selectEntities > span.warning {
  font-weight: 600;
  color: #a11a18;
}

.button-span {
  padding-top: 3%;
  padding-bottom: 3%;
  padding-right: 3%;
  text-align: right;
  float: right;
  display: flex;
  bottom: 0;
  right: 0;
}

.pos-abs {
  position: absolute;
}

.cancel-button-entity-selection {
  padding-right: 1rem;
  display: flex;
}

.cancel-button-entity-selection > button {
  border: 2px solid !important;
}

.input-default,
.input-default:focus {
  outline: none;
  background-color: #fafafa;
  display: block;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background-color: #fafafa;
  color: #666666;
}

.input-placeholder::placeholder {
  color: #999999;
}

.editable,
.editable:focus {
  line-height: 20px;
  font-size: 15px;
  width: 100%;
  font-family: "Noto Serif", serif;
}

.editable-input,
.editable-input:focus {
  height: 2.5rem;
}

.input-padding {
  padding: 0.5rem 15px 0.2rem 15px;
}

.dropdown-padding {
  padding: 0.4rem 15px 0.4rem 15px;
}

.tagSelector {
  position: absolute;
  right: 0;
}

.tagSelector select {
  padding-top: 4px;
  padding-bottom: 4px;
}

.inputHeader {
  display: flex;
}

.required-label {
  color: red;
  padding-left: 0.5rem;
}

.tagEditor {
  max-height: 150px;
  overflow: auto;
}

.default-control-label {
  font-size: 15px;
  text-align: start;
  margin: 0px 0px 0px 0;
  text-transform: capitalize;
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
  min-width: 40%;
  color: #666666;
}

.form-label {
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding-bottom: 0.4rem;
}

.state-generating {
  background-color: cornsilk;
}

.state-generated {
  background-color: forestgreen;
}

.state-failed {
  background-color: firebrick;
}

.state-new {
  background-color: cadetblue;
}

#documents-table td {
  text-align: center;
  font-size: 12px;
}

#documents-modal th {
  background-color: rgb(202, 199, 199);
}

#documents-modal table {
  width: 100%;
}

.tag-selector {
  width: 15rem;
  padding-bottom: 0.4rem;
}

.dropdown-arrow {
  height: 100% !important;
  color: #7260cf !important;
  top: inherit !important;
}

.dropdown-arrow.appBar {
  font-size: 2rem;
}

.action-button {
  margin-left: 15px !important;
}

.paddingLeft-1 {
  padding-left: 1rem;
}

.float-left {
  float: left;
}

.products-logo {
  padding: 1rem;
  width: 20rem;
}

.manageSubscription-button {
  width: 100%;
  padding-top: 3rem;
}

.button-width {
  width: 220px;
}

.edit-footer {
  display: flex;
  justify-content: center;
}

.error-text {
  padding-bottom: 10px;
  font-size: 17px;
  text-align: center;
  width: 100%;
  color: #f44336;
}

.task-summary {
  min-height: 4rem;
}

.task-description {
  min-height: 6rem;
}

textarea.light-text::placeholder {
  color: #999999;
}

img.wireframes-edit {
  width: inherit !important;
  height: inherit !important;
}
